import { getUrlFromString } from '../../../js/document/url'
import { apiCaller } from '../../../js/helpers/api-caller'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import RegularCancellation from './options/regular-cancellation'
import PartialCancellation from './options/partial-cancellation'
import { flush } from '../../../js/document/html-helper'
import registeredEvents from '../../../js/helpers/registered-events'

const EventEmitter = require('eventemitter3')
export const widgetApi = 'w-booking-cancellation-flow'

const classes = {
  hidden: 'is-hidden',
  flowButton: `${widgetApi}__button-flow`,
  rebookingButton: `${widgetApi}__button-rebooking`,
  cancellationButton: `${widgetApi}__button-cancellation`,
  partialCancellationButton: `${widgetApi}__button-partial`
}

export const attr = {
  url: `data-${widgetApi}__url`,
  method: `data-${widgetApi}__method`,
  rebookingModalId: `data-${widgetApi}__rebooking-modal-id`,
  confirmButtonText: `data-${widgetApi}__confirm-button-text`,
  confirmButtonCheckingText: `data-${widgetApi}__confirm-button-checking-text`,
  stepNumber: `data-${widgetApi}__step-number`,
  showStepNumber: `data-${widgetApi}__show-step-number`,
  cancellationResponse: `data-${widgetApi}__cancellation-response`,
  cancellationConditionsUrl: `data-${widgetApi}__cancellation-conditions-url`,
  deleteUrl: `data-${widgetApi}__delete-url`,
  getParticipantsUrl: `data-${widgetApi}__get-participants-url`,
  partialCancellationRequestUrl: `data-${widgetApi}__partial-cancellation-request-url`,
  bookingNumber: `data-${widgetApi}__booking-number`,
  componentId: `data-${widgetApi}__component-id`,
  modalId: `data-${widgetApi}__modal-id`,
  selfserviceLink: `data-${widgetApi}__selfservice-link`,
  track: 'data-track',
  isGroup: `data-${widgetApi}__is-group`
}

export const widgetQueries = {
  cancelModal: `[data-${widgetApi}__cancel-modal]`,
  cancelOptions: `.${widgetApi}__cancel-options`,
  choiceListApi: 'c-choice-list',
  modalApi: 'c-modal',
  buttonApi: 'c-btn',
  nextButton: `.${widgetApi}__button-next`,
  showStepButton: `[data-${widgetApi}__show-step]`,
  confirmButton: `[data-${widgetApi}__confirm-button]`,
  cancelButton: `[data-${widgetApi}__cancel-button]`,
  steps: `.${widgetApi}__step`,
  rebookingModalButton: `[data-${widgetApi}__rebooking-modal]`,
  cancellationResponseResults: `[data-${widgetApi}__cancellation-response]`,
  modalContainer: `[data-${widgetApi}__modal-container]`,
  optionsConfirm: `[data-${widgetApi}__options-confirm]`,
  optionsDecline: `[data-${widgetApi}__options-decline]`,
  optionsBack: `[data-${widgetApi}__options-back]`,
  optionsDone: `[data-${widgetApi}__options-done]`,
  closeModal: `[data-${widgetApi}__close_modal]`,
  form: `[data-${widgetApi}__form]`,
  participantsChoiceList: `[data-${widgetApi}__participants-choice-list]`,
  remarks: `[data-${widgetApi}__remarks]`,
  conditions: `[data-${widgetApi}__conditions]`,
  transformTitle: `[data-${widgetApi}__transform-title]`
}

export default class BookingCancellationFlow {
  /**
   * Creates a new BookingCancellationFlow
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element
   * @param options
   */
  constructor (element, options = {}) {
    if (!element) { return }
    this.element = element
    this.options = options
    this.apis = {}
    this.events = new EventEmitter()

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })

    this._getHtmlElements()
    this.locales = this.getLocales()
    this._attachEvents()
  }

  _getHtmlElements () {
    this.url = this.options.url || this.element.getAttribute(attr.url)
    this.method = this.options.method || this.element.getAttribute(attr.method)
    this.rebookingModalId = this.element.getAttribute(attr.rebookingModalId)
    this.stepElements = this.element.querySelectorAll(widgetQueries.steps)
    this.cancellationResponseResults = this.element.querySelectorAll(widgetQueries.cancellationResponseResults)
    this.cancelModalElement = this.element.querySelector(widgetQueries.cancelModal)
    this.cancelChoiceList = this.cancelModalElement.querySelector(widgetQueries.cancelOptions)
    this.confirmButton = this.element.querySelector(widgetQueries.confirmButton)
    this.cancelButton = this.element.querySelector(widgetQueries.cancelButton)
    this.modalContainer = this.element.querySelector(widgetQueries.modalContainer)
    this.cancellationConditionsUrl = this.element.getAttribute(attr.cancellationConditionsUrl)
    this.deleteUrl = this.element.getAttribute(attr.deleteUrl)
    this.getParticipantsUrl = this.element.getAttribute(attr.getParticipantsUrl)
    this.partialCancellationRequestUrl = this.element.getAttribute(attr.partialCancellationRequestUrl)
    this.bookingNumber = this.element.getAttribute(attr.bookingNumber)
    this.componentId = this.element.getAttribute(attr.componentId)
    this.modalId = this.element.getAttribute(attr.modalId)
    const modal = this.element.querySelector(`[id='${this.modalId}']`)
    this.modalApi = modal && modal[widgetQueries.modalApi]
    this.isGroup = this.element.hasAttribute(attr.isGroup)
  }

  _attachEvents () {
    if (this.confirmButton) {
      this.confirmButton.addEventListener('click', this._clickConfirmButton.bind(this))
      this.apis.confirmButton = this.confirmButton[widgetQueries.buttonApi]
    }

    if (this.cancelButton) {
      this.apis.cancelButton = this.cancelButton[widgetQueries.buttonApi]
      this.apis.cancelButton && this.apis.cancelButton.events.on('clickButton', () => this.events.emit('canceled'))
    }

    const showStepButtons = this.element.querySelectorAll(widgetQueries.showStepButton)
    showStepButtons.forEach(button => {
      button.addEventListener('click', () => {
        if (button.classList.contains(classes.cancellationButton)) {
          this._goToRegularCancellationStep(button)
        } else if (button.classList.contains(classes.partialCancellationButton)) {
          this._goToPartialCancellationStep(button)
        } else {
          this._showStep(button.getAttribute(attr.showStepNumber))
        }
      })
    })

    if (this.cancelChoiceList) {
      this.apis.cancelChoiceList = this.cancelChoiceList[widgetQueries.choiceListApi]
      this.apis.cancelChoiceList && this.apis.cancelChoiceList.events.on('changeOptions', () => this._showFlowButtonsDependingOnCheckedOption())
    }

    this._showFlowButtonsDependingOnCheckedOption()
    this._showRebookingModal()
    this._returnTostepOneWhenClosingModal()
  }

  _goToRegularCancellationStep (button, isIsolated) {
    const buttonApi = button[widgetQueries.buttonApi]
    if (!this.regularCancellation) {
      this.regularCancellation = new RegularCancellation(this.modalContainer, {
        locales: this.locales,
        cancellationConditionsUrl: this.cancellationConditionsUrl,
        deleteUrl: this.deleteUrl,
        bookingNumber: this.bookingNumber,
        modalApi: this.modalApi,
        selfserviceLink: button.getAttribute(attr.selfserviceLink),
        parent: this,
        buttonApi,
        isIsolated,
        events: this.events
      })
    }
    this.regularCancellation.getCancellationConditions()
  }

  _goToPartialCancellationStep (button) {
    const buttonApi = button[widgetQueries.buttonApi]
    if (!this.partialCancellation) {
      this.partialCancellation = new PartialCancellation(this.modalContainer, {
        locales: this.locales,
        getParticipantsUrl: this.getParticipantsUrl,
        partialCancellationRequestUrl: this.partialCancellationRequestUrl,
        bookingNumber: this.bookingNumber,
        modalApi: this.modalApi,
        parent: this,
        buttonApi,
        events: this.events,
        isGroup: this.isGroup
      })
    }
    this.partialCancellation.getParticipants()
  }

  _showFlowButtonsDependingOnCheckedOption () {
    const optionChecked = this.apis.cancelChoiceList.getProp('options').find(option => option.checked)
    const optionType = optionChecked.dataset.wBookingCancellationFlow__optionType

    const buttons = this.cancelModalElement.querySelectorAll(widgetQueries.nextButton)
    const onlyOneButton = this.apis.cancelChoiceList.getProp('options').length === 1
    buttons.forEach(button => {
      button.classList.add(classes.hidden)
      switch (optionType) {
        case 'rebook':
          if (button.classList.contains(classes.rebookingButton)) {
            button.classList.remove(classes.hidden)
          }
          break
        case 'voucher':
          if (button.classList.contains(classes.flowButton)) {
            button.classList.remove(classes.hidden)
          }
          break
        case 'regular':
          if (button.classList.contains(classes.cancellationButton)) {
            button.classList.remove(classes.hidden)
          }
          onlyOneButton && this._showStep(null)
          break
        case 'partial':
          if (button.classList.contains(classes.partialCancellationButton)) {
            button.classList.remove(classes.hidden)
          }
          break
        default:
          button.classList.add(classes.hidden)
          break
      }
    })
  }

  _showRebookingModal () {
    const rebookingModalButton = this.element.querySelector(widgetQueries.rebookingModalButton)
    if (rebookingModalButton) {
      rebookingModalButton.addEventListener('click', () => {
        const modalToOpen = document.getElementById(this.rebookingModalId)
        if (modalToOpen) {
          modalToOpen[widgetQueries.modalApi].open()
        }
      })
    }
  }

  _returnTostepOneWhenClosingModal () {
    if (this.cancelModalElement) {
      this.cancelModalElement[widgetQueries.modalApi].events.on('closed', (eventArgs) => {
        // Reset checked option to the first available
        if (this.apis.cancelChoiceList) {
          this.regularCancellation && this.regularCancellation.stopAsyncCalls()
          this.partialCancellation && this.partialCancellation.stopAsyncCalls()
          const choiceListOptions = this.cancelChoiceList.querySelector('.c-choice-list__options')
          choiceListOptions.querySelectorAll('input').forEach((input, i) => {
            input.checked = (i === 0)
          })
          this.apis.cancelChoiceList.getProp('options').forEach((option, i) => {
            option.checked = (i === 0)
          })
        }
        this._showStep('1')
        this._showFlowButtonsDependingOnCheckedOption()
      })

      this.cancelModalElement[widgetQueries.modalApi].events.on('open', () => {
        const cancellationButton = this.cancelModalElement.querySelector('.' + classes.cancellationButton)
        const options = this.apis.cancelChoiceList.getProp('options')
        if (cancellationButton && options.length === 1) {
          if (options[0].dataset.wBookingCancellationFlow__optionType === 'regular') {
            this._goToRegularCancellationStep(cancellationButton, true)
          }
        }
      })

      this.cancelModalElement[widgetQueries.modalApi].events.on('close', () => {
        if ((this.regularCancellation && this.regularCancellation.cancelled)) {
          window.location.reload()
        }
      })
    }
  }

  _showStep (stepNumberToShow) {
    this.modalContainer && flush(this.modalContainer)
    this.stepElements.forEach(step => {
      const stepNumber = step.getAttribute(attr.stepNumber)
      step.classList.add(classes.hidden)

      if (stepNumber === stepNumberToShow) {
        step.classList.remove(classes.hidden)
        this._setTitleModal(step)
      }
    })
  }

  _setTitleModal (step) {
    const title = step.querySelector(widgetQueries.transformTitle)
    const titleText = title ? title.textContent.trim() : ''
    this.modalApi.setProp('title', titleText)
  }

  async _clickConfirmButton (ev) {
    ev.preventDefault()

    this._setButtonsState(true)
    this._setResultState()

    const result = await this._asyncCall()
    if (result.success && result.response.isValid) {
      this.events.emit('bookingCancelled', result.response)
    }
  }

  async _asyncCall () {
    const url = getUrlFromString(this.url)

    const options = {
      ...(this.method) && { method: this.method },
      ...(this.method && this.method.toUpperCase() === 'GET')
    }
    const result = await apiCaller(url.href, options)

    this._setButtonsState(false)
    if (result.success && result.response.isValid) {
      this._setResultState('success')
    } else if (result.success && !result.response.isValid) {
      this._setResultState('error')
    } else {
      this._setResultState('error')
    }

    return result
  }

  _setButtonsState (isLoading) {
    if (this.confirmButton) {
      const text = isLoading ? this.confirmButton.getAttribute(attr.confirmButtonCheckingText) : this.confirmButton.getAttribute(attr.confirmButtonText)
      this.apis.confirmButton && this.apis.confirmButton.setProps({ disabled: isLoading, loading: isLoading })
      if (text) this.apis.confirmButton && this.apis.confirmButton.setProp('text', text)
    }

    if (this.cancelButton) {
      this.apis.cancelButton && this.apis.cancelButton.setProps({ disabled: isLoading })
    }
  }

  setNextStepButtonsState (isLoading, buttonApi) {
    buttonApi.setProps({ disabled: isLoading, loading: isLoading })
    if (!isLoading) {
      this.cancelChoiceList.enableComponent()
    } else {
      this.cancelChoiceList.disableComponent()
    }
  }

  _setResultState (value = '') {
    if (value !== '') {
      this._showCancellationResponseResult(value)
      this._showStep('3')
    }
  }

  _showCancellationResponseResult (value) {
    this.cancellationResponseResults.forEach(result => {
      const response = result.getAttribute(attr.cancellationResponse)
      if (response === value) {
        result.classList.remove(classes.hidden)
      } else {
        result.classList.add(classes.hidden)
      }
    })
  }

  getLocales () {
    const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.componentId}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) {}

    return customLocaleData || {}
  }
}

registerWidget(BookingCancellationFlow, widgetApi)
